import clsx from 'clsx';
import { useRef, useState, useEffect } from 'react';
import styles from './PulsingCoverLoader.module.scss';
// import RubberIcon from './RubberIcon';

export default function PulsingCoverLoader({
	close = false,
	className,
	children,
}) {
	const [stop, setStop] = useState(close);

	const lastStop = useRef();
	const timer = useRef();
	const mounted = useRef(true);
	useEffect(() => {
		if (lastStop.current !== close) {
			lastStop.current = close;

			if (close) {
				timer.current = setTimeout(() => {
					if (!mounted.current) {
						return;
					}
					setStop(close);
				}, 3000);
			} else {
				clearTimeout(timer.current);
			}
		}
		return () => {
			mounted.current = false;
		};
	}, [close]);

	if (stop) {
		return <></>;
	}

	return (
		<div className={clsx(styles.root, className, close && styles.close)}>
			{children}
		</div>
	);
}
