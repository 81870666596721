// Modified from http://lea.verou.me/2016/12/resolve-promises-externally-with-this-one-weird-trick/
export function defer(cb = () => {}) {
	let res;
	let rej;

	const promise = new Promise((resolve, reject) => {
		res = resolve;
		rej = reject;
		cb(resolve, reject);
	});

	promise.resolve = res;
	promise.reject = rej;

	return promise;
}

export const stableDefer = (messageOrOpts = stableDefer.DEFAULT_OPTS) => {
	const { id, autoStart, callback, timeout, timeoutErrorMessage, onTimeout } = {
		...stableDefer.DEFAULT_OPTS,
		...(typeof messageOrOpts === 'string'
			? {
					timeoutErrorMessage: messageOrOpts,
					onTimeout: () => {},
			  }
			: messageOrOpts),
	};

	if (!timeoutErrorMessage && timeout > 0) {
		// eslint-disable-next-line no-console
		console.warn(
			`No 'timeoutErrorMessage' option given to stableDefer even though timeout is enabled. You should provide one to make it easier to debug`,
		);
	}

	const deferred = defer();
	deferred.id = id;

	let started = false;
	let finished = false;
	let value;
	let error;
	let timer;

	deferred.getValue = async () => {
		if (finished) {
			if (error) {
				throw error;
			}

			return value;
		}

		if (!started) {
			started = true;
			if (timeout > 0) {
				timer = setTimeout(() => {
					const message = timeoutErrorMessage || 'Deferred promise timed out';
					if (typeof onTimeout === 'function') {
						onTimeout(message);
					} else {
						deferred.reject(new Error(message));
					}
				}, timeout);
			}

			callback();
		}

		return deferred;
	};

	const { resolve, reject } = deferred;

	deferred.resolve = (val) => {
		clearTimeout(timer);
		value = val;
		if (!finished) {
			finished = true;
			resolve(value);
		}
	};

	deferred.reject = (ex) => {
		clearTimeout(timer);
		error = ex;
		if (!finished) {
			finished = true;
			reject(error);
		}
	};

	if (autoStart) {
		deferred.getValue();
	}

	return deferred;
};

stableDefer.DEFAULT_OPTS = {
	id: 'stableDefer',
	callback: () => {},
	timeout: 10000,
	timeoutErrorMessage: null,
	autoStart: true,
};

/**
// Simple async lock
// Example usage:

	const guard = new DeferLock();

	// later:
	await guard.lock(); // blocks if already locked
	// ...
	guard.unlock();

	// Elsewhere, for the thing you want to NOT do if guard already locked?
	await guard.lock(); // blocks if already locked
	// ...
	guard.unlock();

*/
export class DeferLock {
	/**
	 * Lock the lock. Blocks if already locked.
	 */
	async lock(/* fail=false */) {
		if (this._lock) {
			// if(fail) {
			// 	return false;
			// }
			await this._lock;
			this._lock = null;
		}
		this._lock = defer();
		return true;
	}

	/**
	 * Unlock the lock. Throws error if no lock.
	 * Marked async for easy adding of .catch() instead of having to wrap calls in try/catch
	 */
	async unlock() {
		if (!this._lock) {
			throw new Error('No lock');
		}
		this._lock.resolve();
		this._lock = null;
	}
}
